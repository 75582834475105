<template>
  <b-card-code title="Detail Mata Pelajaran">
    <b-row class="match-height">
      <b-col
        cols="3"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="require('@/assets/images/avatars/2.png')"
            text="Ardan Ajitra"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                <!-- {{ userData.fullName }} -->
                {{ studentDatas.name }}
              </h4>
              <span class="card-text"> {{ studentDatas.nip }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <!-- <b-button variant="primary"> Edit </b-button>
              <b-button variant="outline-danger" class="ml-1">
                Delete
              </b-button> -->
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        cols="3"
        class="border-left"
      >
        <h5 class="mb-0">
          NIP
        </h5>
        <div class="mb-2 text-secondary">
          {{ NIP }}
        </div>
        <h5 class="mb-0">
          Nama Lengkap
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeName }}
        </div>
        <h5 class="mb-0">
          Nama Panggilan
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeNickname }}
        </div>
        <h5 class="mb-0">
          Gelar Awal
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeFirstTitle }}
        </div>
        <h5 class="mb-0">
          Gelar Akhir
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeLastTitle }}
        </div>
      </b-col>
      <b-col
        cols="3"
        class="border-left"
      >
        <h5 class="mb-0">
          Jenis Kelamin
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeGender }}
        </div>
        <h5 class="mb-0">
          Tempat Lahir
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeBirthPlace }}
        </div>
        <h5 class="mb-0">
          Tanggal Lahir
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeBirthDate }}
        </div>
        <h5 class="mb-0">
          Pendidikan Terakhir
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeEducation }}
        </div>
        <h5 class="mb-0">
          KTP
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeKTP }}
        </div>
        <h5 class="mb-0">
          Agama
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeReligion }}
        </div>
      </b-col>
      <b-col
        cols="3"
        class="border-left"
      >
        <h5 class="mb-0">
          Suku
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeEthnic }}
        </div>
        <h5 class="mb-0">
          Kebangsaan
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeNationality }}
        </div>
        <h5 class="mb-0">
          Status Menikah
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeMarital }}
        </div>
        <h5 class="mb-0">
          Aktif
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeStatus }}
        </div>
        <h5 class="mb-0">
          Tipe Pegawai
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeType }}
        </div>
        <h5 class="mb-0">
          Alamat
        </h5>
        <div class="mb-2 text-secondary">
          {{ EmployeeAddress }}
        </div>
      </b-col>
    </b-row>

    <template #code>
      {{ codeIcon }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BAvatar,
} from 'bootstrap-vue'
// import { codeIcon } from './code'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'DataSiswaEdit',
  components: {
    BCardCode,
    BRow,
    BCol,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    // userData: {
    //   type: Object,
    //   required: true,
    // },
  },
  data: () => ({
    studentDatas: {},
  }),
  computed: {
    studentClass() {
      return this.studentDatas?.academic_classes?.data?.master_class?.data
        ?.class_desc
    },
    studentBirth() {
      return `${this.studentDatas?.birth_place}, ${this.studentDatas?.birth_date}`
    },
    studentAddress() {
      return this.studentDatas?.address
    },
    NIP() {
      return this.studentDatas?.nip
    },
    EmployeeName() {
      return this.studentDatas?.name
    },
    EmployeeNickname() {
      return this.studentDatas?.nickname
    },
    EmployeeFirstTitle() {
      return this.studentDatas?.first_title
    },
    EmployeeLastTitle() {
      return this.studentDatas?.last_title
    },
    EmployeeGender() {
      return this.studentDatas?.gender
    },
    EmployeeBirthPlace() {
      return this.studentDatas?.birth_place
    },
    EmployeeBirthDate() {
      return this.studentDatas?.birth_date
    },
    EmployeeEducation() {
      return this.studentDatas?.major
    },
    EmployeeKTP() {
      return this.studentDatas?.ktp_number
    },
    EmployeeReligion() {
      return this.studentDatas?.religion
    },
    EmployeeEthnic() {
      return this.studentDatas?.ethnic
    },
    EmployeeNationality() {
      return this.studentDatas?.nationality
    },
    EmployeeMarital() {
      return this.studentDatas?.marital_status
    },
    EmployeeStatus() {
      return this.studentDatas?.is_active
    },
    EmployeeType() {
      return this.studentDatas?.religion
    },
    EmployeeAddress() {
      return this.studentDatas?.address
    },
  },
  created() {
    this.detailStudent()
  },
  methods: {
    async detailStudent() {
      try {
        const employeeid = this.$route.params.id
        const response = await this.$http.get(`/employees/${employeeid}`)
        this.studentDatas = response.data.data
      } catch (err) {
        console.log(err)
      }
    },
  },
  setup() {
    // const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      // resolveUserRoleVariant,
    }
  },
}
</script>
